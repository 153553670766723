import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import HeroHeader from "../components/header/heroHeader"
import HighlightSeries from "../components/common/highlightsSeries"
import Seo from "../components/seo/seo"
import { useTranslation } from "react-i18next"
import Modal from "../components/common/modal"
import SubscriptionForm from "../components/forms/subscriptionForm"

const ProduzioneIndustriale = ({ data }) => {

  const { t } = useTranslation();

  return (
  <Layout>
    <Seo
      title={t("produzioneIn10Fasi.seoTitle")}
      description={t("produzioneIn10Fasi.seoDescription")}
      seoImage={data.plant_produttivo.childImageSharp.resize.src}
    />
    <Modal
        component={<SubscriptionForm />}
    /> 
    <HeroHeader
      fluid={data.plant_produttivo.childImageSharp.fluid}
      headerTitle={t("produzioneIn10Fasi.titolo")}
      headerSubtitle={t("produzioneIn10Fasi.sottotitolo")}
      alt={t("produzioneIn10Fasi.heroImageAltTag")}
    />
    <div className="section"></div>
    <HighlightSeries
      title={t("produzioneIn10Fasi.intro")}
      mainOne={t("produzioneIn10Fasi.fase1titolo")}
      descriptionOne={t("produzioneIn10Fasi.fase1descrizione")}
      mainTwo={t("produzioneIn10Fasi.fase2titolo")}
      descriptionTwo={t("produzioneIn10Fasi.fase2descrizione")}
      mainThree={t("produzioneIn10Fasi.fase3titolo")}
      descriptionThree={t("produzioneIn10Fasi.fase3descrizione")}
      mainFour={t("produzioneIn10Fasi.fase4titolo")}
      descriptionFour={t("produzioneIn10Fasi.fase4descrizione")}
      mainFive={t("produzioneIn10Fasi.fase5titolo")}
      descriptionFive={t("produzioneIn10Fasi.fase5descrizione")}
      mainSix={t("produzioneIn10Fasi.fase6titolo")}
      descriptionSix={t("produzioneIn10Fasi.fase6descrizione")}
      mainSeven={t("produzioneIn10Fasi.fase7titolo")}
      descriptionSeven={t("produzioneIn10Fasi.fase7descrizione")}
      mainEight={t("produzioneIn10Fasi.fase8titolo")}
      descriptionEight={t("produzioneIn10Fasi.fase8descrizione")}
      mainNine={t("produzioneIn10Fasi.fase9titolo")}
      descriptionNine={t("produzioneIn10Fasi.fase9descrizione")}
      mainTen={t("produzioneIn10Fasi.fase10titolo")}
      descriptionTen={t("produzioneIn10Fasi.fase10descrizione")}
    />
    <div className="section"></div>
  </Layout>
  )
}

export default ProduzioneIndustriale

export const ProduzioneIndustrialeQuery = graphql`
  query {
    plant_produttivo: file(relativePath: { eq: "Plant_Produttivo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
        resize(width: 900, quality: 90) {
          src
        }
      }
    }
    cablaggio_industriale: file(
      relativePath: { eq: "assemblaggio_cablaggio_industriale.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cablaggio: file(
      relativePath: { eq: "assemblaggio_cablaggio_industriale.jpg" }
    ) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
